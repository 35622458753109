<template lang="pug">
    ui-desktop-mobile-image.medicine-history(imageName="medicine_history")
</template>

<script>
import UiDesktopMobileImage from '../../../../ui/DesktopMobileImage.vue';

export default {
    name: 'medicine-history',
    components: {
        UiDesktopMobileImage,
    },
};
</script>

<style lang="scss" scoped>
.medicine-history {
    width: vw(820);
    min-width: 820px;
    margin: 0 auto;

    @media (--viewport-tablet) {
        width: 100%;
        min-width: unset;
        padding: 0 var(--offset-x);
    }
}
</style>
